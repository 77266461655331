import { NextPage } from 'next';
import { Fragment } from 'react';
// -------- custom component -------- // 
import FigureImage from 'components/reuseable/FigureImage';
import NextLink from 'components/reuseable/links/NextLink';
import * as All from 'data/keywords';
const NotFound: NextPage = () => {
  return (
    <Fragment>  

      <main className="content-wrapper">
        <section className="wrapper bg-light">
          <div className="container pt-12 pt-md-14 pb-14 pb-md-16">
            <div className="row">
              <div className="col-lg-9 col-xl-8 mx-auto">
                <FigureImage width={800} height={316} src="/img/illustrations/404.png" className="mb-10" />
              </div>

              <div className="col-lg-8 col-xl-7 col-xxl-6 mx-auto text-center">
                <h1 className="mb-3">Oops! Page Not Found.</h1>
                <p className="lead mb-7 px-md-12 px-lg-5 px-xl-7">
                  The page you are looking for is not available or has been moved. Try a different page or go to
                  homepage with the button below.
                </p>

                <NextLink title={All.HomeList[0].title} alt={All.HomeList[0].title} href={All.HomeList[0].title.toLowerCase().replace(/ /g, '-')} className="btn btn-primary rounded-pill" />
              </div>

            </div>
          </div>
        </section>
      </main>

      {/* ========== footer section ========== */}
      {/* <Footer1
            address={[
              <>
                {All.SiteIdentity[0].address1},<br></br> {All.SiteIdentity[0].address2},<br></br>{' '}
                {All.SiteIdentity[0].address3}
              </>
            ]}
            email={All.SiteIdentity[0].email}
            phone={All.SiteIdentity[0].phone}
            
          /> */}
    </Fragment>
  );
};

export default NotFound;
